import { extendTheme } from '@chakra-ui/react';
// import defaultColors from '@enums/colors';

import { colors, fonts, components } from './landing-page-cinza';

const theme = extendTheme({
  colors: {
    // ...defaultColors,
    ...colors,
  },
  components: {
    Link: {
      variants: {
        ...components.Link.variants,
      },
    },
    Heading: {
      variants: {
        ...components.Heading.variants,
      },
    },
    Text: {
      variants: {
        ...components.Text.variants,
      },
    },
    Button: {
      variants: {
        ...components.Button.variants,
      },
    },
  },
  fonts: {
    heading: `'DM Sans', sans-serif`,
    body: `'DM Sans', sans-serif`,
    ...fonts,
    mainfont: 'Raleway',
  },
  styles: {
    global: () => ({
      body: {
        overflow: 'auto',
        bg: '#f7fafc',
        color: '#000',
      },
      b: {
        color: 'lbHeaderTextColor',
      },
      'html,body': {
        lineHeight: '1.5',
        fontSize: '16px',
      },
      a: {
        fontFamily: "'TradeGothicLTStd-Cn18','Lato',sans-serif,Verdana",
        fontSize: '16px',
        color: 'white',
      },
      h1: {
        fontFamily: "'TradeGothicLTStd-BdCn20', 'Lato' sans-serif",
        fontSize: '30px',
        fontWeight: 'bold',
        margin: '-10px 0px -10px 20px',
        color: 'grey',
      },
      'h2,h3,h4': {
        fontFamily: "'TradeGothicLTStd-Cn18', 'Lato', sans-serif",
        color: '#363636',
        lineHeight: '1.2',
      },
      span: {
        color: '#9d1d95',
        fontFamily: "'TradeGothicLTStd-Cn18','Lato', sans-serif",
        fontWeight: 'bold',
      },
      'p, li': {
        textAign: 'justify',
        color: '#363636',
        fontFamily: "'TradeGothicLTStd-Cn18','Lato', sans-serif",
        lineHeight: '1.2',
      },
      h2: {
        display: 'block',
        fontSize: '1.5em',
        marginBlockStart: '0.83em',
        marginBlockEnd: '0.83em',
        marginInlineStart: '0px',
        marginInlineEnd: '0px',
        fontWeight: 'bold',
      },
      h3: {
        display: 'block',
        fontSize: '1.17em',
        marginBlockStart: '1em',
        marginBlockEnd: '1em',
        marginInlineStart: '0px',
        marginInlineEnd: '0px',
        fontWeight: 'bold',
      },
      p: {
        display: 'block',
        marginBlockStart: '1em',
        marginBlockEnd: '1em',
        marginInlineStart: '0px',
        marginInlineEnd: '0px',
      },
    }),
  },
});

const themeClean = extendTheme({
  colors: {
    // ...defaultColors,
    ...colors,
  },
  components: {
    Link: {
      variants: {
        ...components.Link.variants,
      },
    },
    Heading: {
      variants: {
        ...components.Heading.variants,
      },
    },
    Text: {
      variants: {
        ...components.Text.variants,
      },
    },
    Button: {
      variants: {
        ...components.Button.variants,
      },
    },
  },
  fonts: {
    heading: `'DM Sans', sans-serif`,
    body: `'DM Sans', sans-serif`,
    ...fonts,
    mainfont: 'Raleway',
  },
  styles: {
    global: () => ({
      body: {
        overflow: 'auto',
        bg: '#f7fafc',
        color: '#000',
      },
      b: {
        color: 'lbHeaderTextColor',
      }
    })
  },
});


export { theme,themeClean };
